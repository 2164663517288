.background {
  max-height: 250px;
}

body {
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  width: 100%;
}

table td, table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

table th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

table tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.05);
}

h1, h2 {
  font-variant: small-caps;
}


.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 40%;
  top: 40%;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

td, th {
  text-align: left;
  max-width:  200px;
}